import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import {UserContext} from "../../context/UserContext";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class NewMembers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      members: null
    };
  }

  componentDidMount() {
    this.fetchMembers();
  }

  fetchMembers() {
    axios.get("/api/admin/new_members")
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            members: response.data.users
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de leden niet ophalen.");
      });
  }

  blockMember(id, user) {
    if (user.id === id) {
      toast.error("Je kan jezelf niet blokkeren!");
      return;
    }

    axios.post('/api/admin/new_members/block', {
      user_id: id
    }).then((response) => {
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success("Gebruiker geblokkeerd!");
        this.fetchMembers();
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Kon de aanvraag niet verwerken, probeer nog eens!");
    });
  }

  verifyMember(id) {
    axios.post('/api/admin/new_members/accept', {
      user_id: id
    }).then((response) => {
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success("Gebruiker geaccepteerd!");
        this.fetchMembers();
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Kon de aanvraag niet verwerken, probeer nog eens!");
    });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Nieuwe Leden</h1>
            <p>In deze lijst kan je de mensen zien die zich geregistreerd hebben maar van wie het account nog niet is aanvaard.</p>
            <p>Als je een account wilt aanvaarden klik je simpelweg op "Accepteer" en de gebruiker van dit account zal vanaf dan kunnen inloggen.</p>
            <p>
              Als je een account niet wilt aanvaarden klik je op "Blokeer" en deze account zal worden geblokkeerd.
              Indien je een account per ongeluk hebt geblokkeerd kan je dit terugdraaien in de lijst van geblokkeerde accounts.
            </p>
            <p>Als je niet zeker bent kan je het account gewoon laten staan totdat iemand anders dit bekijkt.</p>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            {(this.state.members) ? (
              <div className="table-list">
                {this.getMembers(this.state.members)}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
      </div>
    )
  }

  getMembers(members) {
    return (<UserContext.Consumer>
      {({user}) => (
        members.map((member) => {
          const url = "/profile/" + member.user_id;

          return (
            <div key={member.user_id} className="d-flex justify-content-between flex-with-buttons">
              <p>{ member.name } { member.first_name }</p>
              <div>
                <Button variant="info" as={Link} to={url} className="mr-3">Bekijk</Button>
                <Button variant="success" className="mr-3" onClick={() => this.verifyMember(member.user_id)}>Accepteer</Button>
                <Button variant="danger" className="mr-3" onClick={() => this.blockMember(member.user_id, user)}>Blokeer</Button>
              </div>
            </div>
          )
        })
      )}
    </UserContext.Consumer>);
  }
}

export default NewMembers;