import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import {UserContext} from "../../context/UserContext";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class BlockedMembers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      members: null
    };
  }

  componentDidMount() {
    this.fetchMembers();
  }

  fetchMembers() {
    axios.get("/api/admin/blocked_members")
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            members: response.data.users
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de leden niet ophalen.");
      });
  }

  unblockMember(id) {
    axios.post('/api/admin/blocked_members/unblock', {
      user_id: id
    }).then((response) => {
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success("Gebruiker niet langer geblokkeerd!");
        this.fetchMembers();
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Kon de aanvraag niet verwerken, probeer nog eens!");
    });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Geblokkeerde Leden</h1>
            <p>In deze lijst kan je de mensen zien wiens account geblokkeerd is.</p>
            <p>Indien een account reeds aanvaard was voordien zal de gebruiker onmiddellijk zijn account opnieuw kunnen gebruiken.</p>
            <p>Indien het account nog niet reeds aanvaard was zal je eerst bij Nieuwe Leden dit account moeten aanvaarden.</p>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            {(this.state.members) ? (
              <div className="table-list">
                {this.getMembers(this.state.members)}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
      </div>
    )
  }

  getMembers(members) {
    return (<UserContext.Consumer>
      {({user}) => (
        members.map((member) => {
          const url = "/profile/" + member.user_id;

          return (
            <div key={member.user_id} className="d-flex justify-content-between  flex-with-buttons">
              <p>{ member.name } { member.first_name }</p>
              <div>
                <Button variant="info" as={Link} to={url} className="mr-3">Bekijk</Button>
                <Button variant="danger" className="mr-3" onClick={() => this.unblockMember(member.user_id)}>Deblokkeer</Button>
              </div>
            </div>
          )
        })
      )}
    </UserContext.Consumer>);
  }
}

export default BlockedMembers;