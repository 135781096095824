import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

import Moment from 'react-moment';
import 'moment-timezone';

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reservations: null,
      show: null
    };
  }

  componentDidMount() {
    this.fetchReservations();
  }

  fetchReservations() {
    axios.get("/api/show/" + this.props.match.params.show_id)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            reservations: response.data.reservations,
            show: response.data.show
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de reservaties niet ophalen.");
      });
  }

  render() {
    const url = (this.state.show) ? "/manual_reservation/" + this.state.show.id : '';
    const urlSeatings = (this.state.show) ? "/seating_plan/" + this.state.show.id : '';

    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Overzicht voorstelling</h1>
            <p>Selecteer hier een reservatie voor welke je de gegevens wilt bekijken. Je kan hier ook een manuele reservatie toevoegen.</p>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>

            <div>
              {(this.state.show) ? (<h1>
                {this.state.show.name}&nbsp;&nbsp;&nbsp;
                <Moment tz="Europe/Brussels" format="DD-MM-YYYY HH:mm">{Number(this.state.show.show_time)}</Moment>
              </h1>) : <span/>}
              <div>
                <Button className="mr-3 mb-3" variant="success" as={Link} to={urlSeatings}>Zaalplan Maken</Button>
                <Button className="mr-3 mb-3" variant="info" as={Link} to={url}>Manuele Reservatie</Button>
              </div>
            </div>

            {(this.state.reservations) ? (
              <div className="table-list">
                {this.getReservations(this.state.reservations)}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
      </div>
    )
  }

  getReservations(reservations) {
    return reservations.map((reservation) => {
      const url = "/reservation/" + reservation.id;

      return (
        <div key={reservation.id} className="d-flex justify-content-between flex-with-buttons">
          <p>
            {reservation.name} {reservation.firstName} - {reservation.email} - {reservation.tickets} tickets -
            {(reservation.payed) ? ' betaald' : ' onbetaald'}
            {(reservation.manual) ? ' (manueel)' : ''}
            {(reservation.has_uitpas) ? ' - UITPAS' : ''}
          </p>
          <div>
            <Button variant="info" as={Link} to={url} className="mr-3">Bekijk Reservatie</Button>
          </div>
        </div>
      )
    })
  }
}

export default Show;