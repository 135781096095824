import React from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import InputMask from 'react-input-mask';

import Card from "../components/Card";
import Loader from "../components/Loader";
import {UserContext} from "../context/UserContext";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: '',
      user: null,
      data: {
        pass: '',
        newpass: '',
        newpass1: ''
      }
    };

    this.changeName = this.changeName.bind(this);
    this.changeFirstName = this.changeFirstName.bind(this);
    this.changeMail = this.changeMail.bind(this);
    this.changeStreet = this.changeStreet.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.changePostal = this.changePostal.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.changeBirthday = this.changeBirthday.bind(this);
    this.changeOpendoek = this.changeOpendoek.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.payMembership = this.payMembership.bind(this);

    this.changePass = this.changePass.bind(this);
    this.changeNewpass = this.changeNewpass.bind(this);
    this.changeNewpass1 = this.changeNewpass1.bind(this);
    this.updatePass = this.updatePass.bind(this);
  }

  componentDidMount() {
    this.updatePage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.updatePage();
    }
  }

  updatePage() {
    let url;

    if (this.props.match && this.props.match.params && this.props.match.params.user_id) {
      url = "/api/profile/" + this.props.match.params.user_id;
    } else {
      url = "/api/profile";
    }

    this.setState({
      url: url,
      user: null
    }, this.fetchUser);
  }

  fetchUser() {
    axios.get(this.state.url)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            user: response.data.user
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon profiel niet ophalen.");
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            {(this.state.user) ? (
              <div>
                {this.getProfile()}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>

        {(this.state.user && this.state.user.own_user) ? (
          <Row>
            <Col md={1} />
            <Card md={10}>
              <form onSubmit={this.updatePass}>
                <h4>Wachtwoord bijwerken</h4>

                <div className="form-group">
                  <label data-for="pass">Huidig wachtwoord</label>
                  <input key="pass" name="pass" type="password" value={this.state.data.pass} onChange={this.changePass} className="form-control" placeholder="Huidig wachtwoord" autoComplete="current-password" required/>
                </div>

                <div className="form-group">
                  <label data-for="pass">Nieuw wachtwoord</label>
                  <input key="newpass" name="newpass" type="password" value={this.state.data.newpass} onChange={this.changeNewpass} className="form-control" placeholder="Nieuw wachtwoord" autoComplete="new-password" required/>
                </div>

                <div className="form-group">
                  <label data-for="newpass1">Nieuw wachtwoord herhalen</label>
                  <input key="newpass1" name="newpass1" type="password" value={this.state.data.newpass1} onChange={this.changeNewpass1} className="form-control" placeholder="Nieuw wachtwoord herhalen" autoComplete="new-password" required/>
                </div>

                <div className="form-group">
                  {(this.state.pending) ? (
                    <Loader />
                  ) : (
                    <button className="btn btn-primary btn-flat">Bijwerken</button>
                  )}
                </div>
              </form>
            </Card>
          </Row>
        ) : ('')}

        <UserContext.Consumer>
          {({user}) => (
            (user.permissions.indexOf("administrator") > -1 && this.state.user) ? (
              <Row>
                <Col md={1} />
                <Card md={10}>
                  <h4>Adminbeheer</h4>
                  <Button as={Link} to={"/admin/addtogroup/" + this.state.user.user_id} variant="success">Toevoegen aan groepen</Button>
                </Card>
              </Row>
            ) : ('')
          )}
        </UserContext.Consumer>
      </div>
    )
  }

  getProfile() {
    return (
      <div>
        <form onSubmit={this.updateProfile}>
          <h1>{this.state.user.name}'s profiel</h1>

          <div className="form-group">
            <label data-for="name">Naam</label>
            <input key="name" name="name" type="text" value={this.state.user.name} onChange={this.changeName} className="form-control" placeholder="Naam" required/>
          </div>

          <div className="form-group">
            <label data-for="name">Voornaam</label>
            <input key="fname" name="fname" type="text" value={this.state.user.first_name} onChange={this.changeFirstName} className="form-control" placeholder="Voornaam" required/>
          </div>

          <div className="form-group">
            <label data-for="mail">Noteer hier het e-mailadres dat u voortaan gebruikt om u aan te melden of in te loggen op de ledenzone.</label>
            <input key="mail" name="mail" type="email" value={this.state.user.email} onChange={this.changeMail} className="form-control" placeholder="E-mailadres" required/>
          </div>

          <div className="form-group">
            <label data-for="street">Adres</label>
            <input key="street" name="street" type="text" value={this.state.user.street} onChange={this.changeStreet} className="form-control" placeholder="Adres"/>
          </div>

          <div className="form-group">
            <label data-for="city">Stad</label>
            <input key="city" name="city" type="text" value={this.state.user.city} onChange={this.changeCity} className="form-control" placeholder="Stad"/>
          </div>

          <div className="form-group">
            <label data-for="postal">Postcode</label>
            <input key="postal" name="postal" type="text" value={this.state.user.postal} onChange={this.changePostal} className="form-control" placeholder="Postcode"/>
          </div>

          <div className="form-group">
            <label data-for="phone">Telefoon</label>
            <input key="phone" name="phone" type="text" value={this.state.user.phone} onChange={this.changePhone} className="form-control" placeholder="Telefoon"/>
          </div>

          <div className="form-group">
            <label data-for="birthday">Geboortedatum (dd-mm-yyyy)</label>
            <InputMask mask="99-99-9999" key="birthday" name="birthday" type="text" value={this.state.user.birthday} onChange={this.changeBirthday} className="form-control"/>
          </div>

          <div className="form-group">
            <label data-for="opendoek">Lidnummer Opendoek</label>
            <InputMask mask="L99.999999" key="opendoek" name="opendoek" type="text" value={this.state.user.opendoek} onChange={this.changeOpendoek} className="form-control"/>
          </div>

          <div className="form-group">
            <label data-for="phone">Lidgeld huidig seizoen</label>
            <input key="membership" name="membership" type="text" value={(this.state.user.membership_valid) ? 'Betaald' : 'Niet betaald'} className="form-control" disabled/>
          </div>

          <div className="form-group">
            {(this.state.pending) ? (
              <Loader />
            ) : (
              <div>
                {(!this.state.user.membership_valid && this.state.user.own_user) ? (
                  <Button onClick={this.payMembership} className="mr-3" variant="success">Lidgeld betalen</Button>
                ) : ('')}
                <button className="btn btn-primary btn-flat">Bijwerken</button>
              </div>
            )}
          </div>
        </form>

      </div>);
  }

  changeName(event) {
    const user = this.state.user;
    user.name = event.target.value;
    this.setState({user: user});
  }

  changeFirstName(event) {
    const user = this.state.user;
    user.first_name = event.target.value;
    this.setState({user: user});
  }

  changeMail(event) {
    const user = this.state.user;
    user.email = event.target.value;
    this.setState({user: user});
  }

  changeStreet(event) {
    const user = this.state.user;
    user.street = event.target.value;
    this.setState({user: user});
  }

  changeCity(event) {
    const user = this.state.user;
    user.city = event.target.value;
    this.setState({user: user});
  }

  changePostal(event) {
    const user = this.state.user;
    user.postal = event.target.value;
    this.setState({user: user});
  }

  changePhone(event) {
    const user = this.state.user;
    user.phone = event.target.value;
    this.setState({user: user});
  }

  changeBirthday(event) {
    const user = this.state.user;
    user.birthday = event.target.value;
    this.setState({user: user});
  }

  changeOpendoek(event) {
    const user = this.state.user;
    user.opendoek = event.target.value;
    this.setState({user: user});
  }

  updateProfile(event) {
    event.preventDefault();

    if (!this.state.user.name || this.state.user.name === "") {
      toast.error("Gelieve een naam in te vullen!");
      return;
    }

    if (!this.state.user.email || this.state.user.email === "") {
      toast.error("Gelieve een e-mailadres in te vullen!");
      return;
    }

    this.setState({
      pending: true
    });

    axios.post(this.state.url + "/update", {
      name: this.state.user.name,
      first_name: this.state.user.first_name,
      email: this.state.user.email,
      street: this.state.user.street,
      city: this.state.user.city,
      postal: this.state.user.postal,
      phone:  this.state.user.phone,
      birthday: this.state.user.birthday,
      opendoek: this.state.user.opendoek
    }).then((response) => {
      if (response.data.success) {
        toast.success("Profiel bijgewerkt!");
      } else {
        toast.error(response.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Er is iets fout gegaan bij het bijwerken van het profiel.");
    }).finally(() => {
      this.setState({
        pending: false
      });
    })
  }

  changePass(event) {
    const data = this.state.data;
    data.pass = event.target.value;
    this.setState({data: data});
  }

  changeNewpass(event) {
    const data = this.state.data;
    data.newpass = event.target.value;
    this.setState({data: data});
  }

  changeNewpass1(event) {
    const data = this.state.data;
    data.newpass1 = event.target.value;
    this.setState({data: data});
  }

  updatePass(event) {
    event.preventDefault();

    if (!this.state.data.pass || this.state.data.pass === "") {
      toast.error("Gelieve uw huidig wachtwoord in te vullen!");
      return;
    }

    if (!this.state.data.newpass || this.state.data.newpass === "") {
      toast.error("Gelieve een nieuw wachtwoord in te vullen!");
      return;
    }

    if (!this.state.data.newpass1 || this.state.data.newpass1 === "") {
      toast.error("Gelieve het nieuwe wachtwoord te herhalen!");
      return;
    }

    if (this.state.data.newpass !== this.state.data.newpass1) {
      toast.error("Nieuwe wachtwoorden komen niet overeen!");
      return;
    }

    if (this.state.data.newpass === this.state.data.pass) {
      toast.error("Wachtwoord is niet gewijzigd!");
      return;
    }

    this.setState({
      pending: true
    });

    axios.post(this.state.url + "/passchange", {
      current_pass: this.state.data.pass,
      new_pass: this.state.data.newpass,
      new_repeat_pass: this.state.data.newpass1
    }).then((response) => {
      if (response.data.success) {
        toast.success("Wachtwoord bijgewerkt!");
        this.setState({
          data: {
            pass: '',
            newpass: '',
            newpass1: ''
          }
        })
      } else {
        toast.error(response.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Er is iets fout gegaan bij het bijwerken van het wachtwoord.");
    }).finally(() => {
      this.setState({
        pending: false
      });
    })
  }

  payMembership() {

    this.setState({
      pending: true
    });

    axios.get("/api/pay_membership")
      .then((response) => {
        if (response.data.success) {
          window.location = response.data.redirect;
        } else {
          toast.error(response.data.message);
          this.setState({
            pending: false
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Er is iets fout gegaan bij de aanvraag.");
        this.setState({
          pending: false
        });
      });
  }
}

export default Profile;