import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class Mailing extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      subject: undefined,
      content: undefined,
      users: [],
      groups: [],
      special: undefined,

      members: undefined
    };

    this.changeSubject = this.changeSubject.bind(this);
    this.changeContent = this.changeContent.bind(this);
    this.changeUsers = this.changeUsers.bind(this);
    this.changeSpecial = this.changeSpecial.bind(this);
    this.send = this.send.bind(this);
  }

  componentDidMount() {
    this.fetchMembers();
  }

  fetchMembers() {
    axios.get("/api/admin/members")
        .then((response) => {
          if (!response.data.success) {
            toast.error(response.data.message);
          } else {
            this.setState({
              members: response.data.users
            }, this.filter);
          }
        }).catch((error) => {
          console.log(error);
          toast.error("Kon de leden niet ophalen.");
        });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Nieuwsbrief Verzenden</h1>

            <form onSubmit={this.send}>

              <div className="form-group">
                <label data-for="subject">Onderwerp</label>
                <input key="subject" name="subject" type="text" value={this.state.subject} onChange={this.changeSubject} className="form-control" required/>
              </div>

              <div className="form-group">
                <label data-for="content">Inhoud</label>
                <textarea key="content" name="content" rows={10} value={this.state.content} onChange={this.changeContent} className="form-control" required/>
              </div>

              <div className="form-group">
                <label data-for="content">Individuele leden</label>
                <Select
                    isLoading={!this.state.members}
                    isSearchable={true}
                    closeMenuOnSelect={false}
                    isMulti
                    options={this.getMembers()}
                    onChange={this.changeUsers}
                />
              </div>

              <div className="form-group">
                <label data-for="content">Speciale Lijst</label>
                <Select
                    options={[
                      { value: 'PAID', label: 'Lidgeld Betaald'},
                      { value: 'NOT_PAID', label: 'Lidgeld Niet Betaald'}
                    ]}
                    onChange={this.changeSpecial}
                />
              </div>

              <div className="form-group">
                {(this.state.pending) ? (
                    <Loader />
                ) : (
                    <Button onClick={this.send} className="mr-3" variant="success">Mail Verzenden</Button>
                )}
              </div>

            </form>
          </Card>
        </Row>
      </div>
    )
  }

  getMembers() {
    const members = [];

    if (this.state.members != null) {
      this.state.members.forEach(member => {
        members.push({
          value: member.user_id, label: member.name + ' ' + member.first_name
        })
      })
    }

    return members;
  }

  changeSubject(event) {
    this.setState({
      subject: event.target.value
    });
  }

  changeContent(event) {
    this.setState({
      content: event.target.value
    });
  }

  changeUsers(values) {
    const users = []
    values.forEach(value => {
      users.push(value.value)
    })
    this.setState({
      users: users
    })
  }

  changeSpecial(value) {
    this.setState({
      special: value.value
    })
  }

  send() {

    if (!this.state.subject) {
      return toast.error("Gelieve een onderwerp in te geven!");
    }

    if (!this.state.content) {
      return toast.error("Gelieve een inhoud in te geven!");
    }

    this.setState({
      pending: true
    });

    axios.post("/api/mailing/send", {
      subject: this.state.subject,
      content: this.state.content.replaceAll('\n', '<br />'),
      users: this.state.users,
      special: this.state.special
    }).then((response) => {
      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }

      this.setState({
        pending: false
      });
    }).catch((error) => {
      console.log(error);
      toast.error("Er is iets fout gegaan bij de aanvraag.");
      this.setState({
        pending: false
      });
    });

  }
}

export default Mailing;