import Permissions from '../util/Permissions';

class PermissionThread {
  constructor(fetchUser, updatePermissions) {
    this.fetchUser = fetchUser;
    this.updatePermissions = updatePermissions;

    this.interval = setInterval(this.fetch.bind(this), 60 * 1000);
  }

  destroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  async fetch() {
    const user = this.fetchUser();

    if (user) {
      const permissions = await Permissions(user.id);

      if (permissions) {
        this.updatePermissions(permissions);
      }
    }
  }
}

export default PermissionThread;