import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class Subscriptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptions: null
    };
  }

  componentDidMount() {
    this.fetchSubscriptions();
  }

  fetchSubscriptions() {
    axios.get("/api/subscriptions")
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            subscriptions: response.data.subscriptions
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de abonnementen niet ophalen.");
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Abonnementen</h1>
            <p>In deze lijst zie je een overzicht van de abonnementen</p>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            {(this.state.subscriptions) ? (
              <div className="table-list">
                {this.getSubscriptions(this.state.subscriptions)}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
      </div>
    )
  }

  getSubscriptions(subscriptions) {
    return subscriptions.map((subscription) => {
      const url = "/subscription/" + subscription.subscription;

      return (
        <div key={subscription.subscription} className="d-flex justify-content-between">
          <p>{ subscription.name }</p>
          <div>
            <Button as={Link} to={url} className="btn btn-info mr-3">Bekijk</Button>
          </div>
        </div>
      )
    });
  }
}

export default Subscriptions;