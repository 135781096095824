import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNewspaper} from "@fortawesome/free-solid-svg-icons/faNewspaper";

import 'moment-timezone';
import 'moment/locale/nl';

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Card from "../../components/Card";
import Loader from "../../components/Loader";
import {Link} from "react-router-dom";

class Subscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: null
    };
  }

  componentDidMount() {
    axios.get("/api/subscription/" + this.props.match.params.subscription_id)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            subscription: response.data.subscription,
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon het abonnement niet ophalen.");
      });
  }

  render() {
    return (
      <div>

        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Overzicht abonnement</h1>
            {(this.state.subscription) ? (
              <p>
                {this.state.subscription.name} {this.state.subscription.firstName}
                <br />
                {this.state.subscription.street}
                <br />
                {this.state.subscription.postal} {this.state.subscription.city}
                <br />
                &#9993; {this.state.subscription.email}
                <br />
                &#9742; {this.state.subscription.phone}
                <br />
                <FontAwesomeIcon icon={faNewspaper} /> {(this.state.subscription.newsletter) ? 'Wilt nieuwsbrief' : 'Geen nieuwsbrief'}
              </p>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>

        {(this.state.subscription) ? (
          <Row>
            <Col md={1} />
            <Card md={10}>
              {(Object.keys(this.state.subscription.reservations).length > 0) ? (
                <div className="table-list">
                  {this.getReservations(this.state.subscription.reservations)}
                </div>
              ) : (
                <p>Nog geen reservaties gelinked.</p>
              )}
            </Card>
          </Row>
        ) : ('')}
      </div>
    )
  }

  getReservations(reservations) {
    let data = [];

    for (const [show, reservation] of Object.entries(reservations)) {
      const url = "/reservation/" + reservation;

      data.push(
        <div key={show} className="d-flex justify-content-between flex-with-buttons">
          <p>{show}</p>
          <div>
            <Button as={Link} to={url} className="btn btn-info mr-3">Bekijk reservatie</Button>
          </div>
        </div>
      )
    }

    return data;
  }
}

export default Subscription;