import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import Row from "react-bootstrap/Row";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      firstName: "",
      email: "",
      pass: "",
      pass1: "",
      pending: false
    };

    this.changeName = this.changeName.bind(this);
    this.changeFirstName = this.changeFirstName.bind(this);
    this.changeMail = this.changeMail.bind(this);
    this.changePass = this.changePass.bind(this);
    this.changePass1 = this.changePass1.bind(this);
    this.register = this.register.bind(this);
  }

  render() {
    return (
      <Row>
        <Card className="login-box" md={4}>
          <h1>TDM Ledenzone</h1>
          <p>Registreer je om de ledenzone te kunnen gebruiken.</p>

          <form onSubmit={this.register}>
            <div className="form-group">
              <input id="name" name="name" type="text" value={this.state.name} onChange={this.changeName} className="form-control" placeholder="Naam" autoComplete="name" required/>
            </div>

            <div className="form-group">
              <input id="fname" name="fname" type="text" value={this.state.firstName} onChange={this.changeFirstName} className="form-control" placeholder="Voornaam" autoComplete="first-name" required/>
            </div>

            <div className="form-group">
              <input id="email" name="email" type="email" value={this.state.email} onChange={this.changeMail} className="form-control" placeholder="E-mailadres" autoComplete="email" required/>
            </div>

            <div className="form-group">
              <input id="pass" name="pass" type="password" value={this.state.pass} onChange={this.changePass} className="form-control" placeholder="Wachtwoord" autoComplete="current-password" required/>
            </div>

            <div className="form-group">
              <input id="pass1" name="pass1" type="password" value={this.state.pass1} onChange={this.changePass1} className="form-control" placeholder="Herhaal Wachtwoord" autoComplete="password-repeat" required/>
            </div>

            <div className="form-group">
              {(this.state.pending) ? (
                <Loader />
              ) : (
                <button className="btn btn-primary btn-block btn-flat">
                  Registreren
                </button>
              )}
            </div>
          </form>

          <div className="d-flex justify-content-between">
            <Link to="/">Aanmelden</Link>
            <Link to="/forgotpass">Wachtwoord Vergeten</Link>
          </div>
        </Card>
      </Row>
    );
  }

  changeName(event) {
    this.setState({name: event.target.value});
  }

  changeFirstName(event) {
    this.setState({firstName: event.target.value});
  }

  changeMail(event) {
    this.setState({email: event.target.value});
  }

  changePass(event) {
    this.setState({pass: event.target.value});
  }

  changePass1(event) {
    this.setState({pass1: event.target.value});
  }

  async register(e) {
    e.preventDefault();

    if (!this.state.name || this.state.name === "") {
      toast.error("Gelieve een naam in te vullen!");
      return;
    }

    if (!this.state.firstName || this.state.firstName === "") {
      toast.error("Gelieve een voornaam in te vullen!");
      return;
    }

    if (!this.state.email || this.state.email === "") {
      toast.error("Gelieve een e-mailadres in te vullen!");
      return;
    }

    if (!this.state.pass || this.state.pass === "") {
      toast.error("Gelieve een wachtwoord in te vullen!");
      return;
    }

    if (!this.state.pass1 || this.state.pass1 === "") {
      toast.error("Gelieve je wachtwoord te herhalen!");
      return;
    }

    if (this.state.pass !== this.state.pass1) {
      toast.error("U wachtwoorden komen niet over elkaar");
      return;
    }

    this.setState({
      pending: true
    });

    axios.post("/api/register", {
      name: this.state.name,
      first_name: this.state.firstName,
      email: this.state.email,
      pass: this.state.pass
    }).then((response) => {
      if (response.data.success) {
        toast.success("Successvol nieuw account aangemaakt. Iemand van het bestuur moet nu je account goedkeuren. Je zal een email ontvangen van zodra dit gebeurd is.", {autoClose: false});
      } else {
        toast.error(response.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Er is iets fout gegaan bij het registreren. Indien dit blijft gebeuren gelieve dit te melden!");
    }).finally(() => {
      this.setState({
        pending: false
      });
    });
  }
}

export default Register;