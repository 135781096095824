import React from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import Moment from "react-moment";
import {faArrowDown, faArrowUp, faCheck, faGripLines, faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loader from "../components/Loader";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {UserContext} from '../context/UserContext';

import Card from '../components/Card';

import './Tasks.css';

class Tasks extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tasks: null,
      updatingTask: false,
      modal: false,
      task: {},
      edit: false,
      saving: false,
      deleteModal: false,
      deleteTask: null
    }

    this.changeTitle = this.changeTitle.bind(this);
    this.changePriority = this.changePriority.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.saveTask = this.saveTask.bind(this);
    this.deleteTask = this.deleteTask.bind(this);

  }

  componentDidMount() {
    this.fetchTasks();
  }

  fetchTasks() {
    axios.get('/api/tasks/')
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            tasks: response.data.tasks.map((task) => {
              const completed_at = Number(task.completed_at);

              return {
                ...task,
                created_at: Number(task.created_at),
                completed_at,
                completed: !isNaN(completed_at) && completed_at > 0
              }
            }),
            updatingTask: false,
            modal: false
          })
        }
      }).catch((error) => {
        console.log(error);
        toast.error('Kon de taken niet laden.');
      });
  }

  toggleCompleted(task) {
    this.setState({
      updatingTask: true
    })

    axios.post('/api/tasks/' + task.id + '/complete', {
      completed: !task.completed
    })
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
          this.setState({
            updatingTask: false
          });
        } else {
          this.fetchTasks();
        }
      }).catch((error) => {
        console.log(error);
        toast.error('Kon de status niet aanpassen.');
      });
  }

  deleteTask() {
    if (!this.state.deleteTask) {
      return;
    }

    const id = this.state.deleteTask.id;

    this.setState({
      updatingTask: true,
      deleteModal: false,
      deleteTask: null
    })

    axios.delete('/api/tasks/' + id)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
          this.setState({
            updatingTask: false
          });
        } else {
          this.fetchTasks();
        }
      }).catch((error) => {
        console.log(error);
        toast.error('Kon de taak niet verwijderen.');
      });
  }

  createTask() {
    this.setState({
      task: {
        title: '',
        priority: 'NORMAL',
        description: ''
      },
      edit: false,
      modal: true
    })
  }

  editTask(task) {
    this.setState({
      task: {
        ...task
      },
      edit: true,
      modal: true
    })
  }

  changeTitle(event) {
    const task = this.state.task;
    task.title = event.target.value;
    this.setState({task});
  }

  changeDescription(event) {
    const task = this.state.task;
    task.description = event.target.value;
    this.setState({task});
  }

  changePriority(event) {
    const task = this.state.task;
    task.priority = event.target.value;
    this.setState({task});
  }

  showModal() {
    this.setState({
      modal: true
    })
  }

  hideModal() {
    this.setState({
      modal: false
    })
  }

  validateCanSave() {
    if (this.state.saving) {
      return false;
    }

    if (!this.state.task.title) {
      toast.error("Titel is verplicht!");
      return false;
    }
    if (!this.state.task.priority) {
      toast.error("Prioriteit is verplicht!");
      return false;
    }

    this.setState({
      saving: true
    });

    return true;
  }

  saveTask() {
    if (this.state.edit && !this.validateCanSave()) {
      return;
    }

    axios.post('/api/tasks/', {
      task: this.state.task
    })
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.fetchTasks();
        }
      }).catch((error) => {
        console.log(error);
        toast.error('Kon de taak niet opslaan.');
      }).finally(() => {
        this.setState({
          saving: false
        })
      });
  }

  updateTask() {
    if (!this.state.edit && !this.validateCanSave()) {
      return;
    }

    axios.patch('/api/tasks/' + this.state.task.id, {
      task: this.state.task
    })
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.fetchTasks();
        }
      }).catch((error) => {
        console.log(error);
        toast.error('Kon de taak niet opslaan.');
      }).finally(() => {
        this.setState({
          saving: false
        })
      });
  }

  showDeleteModal(task) {
    this.setState({
      deleteModal: true,
      deleteTask: task
    })
  };

  hideDeleteModal() {
    this.setState({
      deleteModal: false
    })
  }

  render() {

    return (
      <UserContext.Consumer>
        {({user}) => (

          <div>
            <Card className="d-flex flex-with-buttons justify-content-sm-between d-print-none">
              <h1><strong>Taken</strong></h1>
              <Button variant="success" onClick={this.createTask.bind(this)}>
                Nieuw
              </Button>
            </Card>
            <Card className="d-print-none">
              <p>
                <div className="list-group">

                  { this.state.tasks && this.state.tasks.map(task => (
                    <a key={task.id} href="#"
                       className={`list-group-item list-group-item-action ${task.completed ? "completed " : ""}`}
                       aria-current="true"
                    >
                      <div className="d-flex w-100">

                        { task.priority === 'HIGH' && (<FontAwesomeIcon icon={faArrowUp} className="mr-2 high"/>)}
                        { task.priority === 'NORMAL' && (<FontAwesomeIcon icon={faGripLines} className="mr-2 normal"/>)}
                        { task.priority === 'LOW' && (<FontAwesomeIcon icon={faArrowDown} className="mr-2 low"/>)}

                        <div className="d-flex flex-column flex-grow-1">
                          <div className="d-flex flex-grow-1 justify-content-between">
                            <h5 className="mb-1">{ task.title }</h5>
                            <small>
                              <Moment tz="Europe/Brussels" fromNow={true}>{task.created_at}</Moment>
                            </small>
                          </div>
                          <div className="d-flex flex-grow-1 justify-content-between">
                            <p className="creator mb-1">
                              { task.created_by }
                              { task.completed_at > 0 && [', voltooid door ', task.completed_by, ' op ', <Moment tz="Europe/Brussels" format="DD-MM-YYYY HH:mm">{Number(task.completed_at)}</Moment>] }
                            </p>
                            { this.state.updatingTask ?
                              (<Loader />) :
                              (<div>
                                <FontAwesomeIcon icon={faCheck} className="mr-2 toggle" onClick={() => this.toggleCompleted(task)}/>
                                <FontAwesomeIcon icon={faPen} className="mr-2 edit" onClick={() => this.editTask(task)}/>
                                <FontAwesomeIcon icon={faTrash} className="mr-2 delete" onClick={() => this.showDeleteModal(task)}/>
                              </div>)
                            }
                          </div>
                          <small>{ task.description }</small>
                        </div>

                      </div>
                    </a>
                  ))}

                </div>
              </p>
            </Card>

            <Modal show={this.state.modal} onHide={this.hideModal.bind(this)}>
              <Modal.Header closeButton>
                <Modal.Title>{ this.state.edit ? 'Taak bewerken' : 'Taak maken' }</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={this.state.edit ? this.updateTask : this.saveTask}>

                  <div className="form-group">
                    <label data-for="title">Titel</label>
                    <input
                      key="title"
                      name="title"
                      type="text"
                      value={this.state.task.title}
                      onChange={this.changeTitle}
                      className="form-control"
                      placeholder="Titel"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label data-for="title">Prioriteit</label>
                    <select
                      key="priority"
                      name="priority"
                      value={this.state.task.priority}
                      onChange={this.changePriority}
                      className="form-control"
                      placeholder="Prioriteit"
                      required
                    >
                      <option value="HIGH">Hoog</option>
                      <option value="NORMAL">Normaal</option>
                      <option value="LOW">Laag</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label data-for="description">Omschrijving</label>
                    <textarea
                      key="description"
                      name="description"
                      value={this.state.task.description}
                      onChange={this.changeDescription}
                      className="form-control"
                    />
                  </div>

                </form>
              </Modal.Body>
              {
                this.state.saving ?
                  (
                    <Modal.Footer>
                      <Loader />
                    </Modal.Footer>
                  ) : (
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.hideModal.bind(this)}>
                        Annuleren
                      </Button>
                      <Button variant="primary" onClick={this.state.edit ? this.updateTask : this.saveTask}>
                        Opslaan
                      </Button>
                    </Modal.Footer>
                  )

              }
            </Modal>

            <Modal show={this.state.deleteModal} onHide={this.hideDeleteModal.bind(this)}>
              <Modal.Header closeButton>
                <Modal.Title>Taak verwijderen</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Ben je zeker dat je taak <b>{ this.state.deleteTask?.title }</b> wilt verwijderen?
              </Modal.Body>
              {
                this.state.saving ?
                  (
                    <Modal.Footer>
                      <Loader />
                    </Modal.Footer>
                  ) : (
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.hideDeleteModal.bind(this)}>
                        Annuleren
                      </Button>
                      <Button variant="danger" onClick={this.deleteTask}>
                        Verwijder
                      </Button>
                    </Modal.Footer>
                  )

              }
            </Modal>
          </div>

        )}

      </UserContext.Consumer>
    )
  }

}

export default Tasks;
