import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import moment from "moment";

import {UserContext} from "../context/UserContext";

import Button from 'react-bootstrap/Button';

import Card from '../components/Card';
import Loader from "../components/Loader";

import './CovidCalendar.css';

class CovidCalendar extends React.Component {

  months = [1, 2, 3, 4, 5, 6];

  constructor(props) {
    super(props);

    this.state = {
      entries: null,
      pending_update: false
    };

    this.claimEntry = this.claimEntry.bind(this);
    this.removeEntry = this.removeEntry.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.fetchCalendar();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetchCalendar();
    }
  }

  fetchCalendar() {
    axios.get("/api/covidcalendar/" + this.props.match.params.group)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {

          const days = new Map();

          response.data.entries.forEach(entry => {
            const day = moment.tz(Number(entry.day), "Europe/Brussels");
            const key = day.format('YYYY-MM-DD');

            let dayEntries = days.get(key);

            if (!dayEntries) {
              dayEntries = [];
              days.set(key, dayEntries);
            }

            dayEntries.push(entry);
          });

          this.setState({
            entries: days
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de kalender niet ophalen.");
      });
  }

  render() {
    return (
      <div>
        <Card className="d-flex flex-with-buttons justify-content-sm-between d-print-none">
          <h1><strong>Kalender</strong></h1>
          {(this.state.pending_update) ? <Loader /> :
            <div>
              <UserContext.Consumer>
                {({user}) => {

                  let buttons = [];

                  buttons.push(<Button onClick={() => this.save(user.id)} variant="success">Opslaan</Button>);

                  return buttons;
                }}
              </UserContext.Consumer>
            </div>
          }
        </Card>
        <Card className="d-print-none">
          <p>
            Om een datum als afwezig aan te duiden klik je op het respectievelijke vakje. Nadat je alle gekozen vakjes geselecteerd hebt klik je op opslaan om je keuze's op te slaan.
          </p>
        </Card>
        <div className="covidCalendarContainer">
          <UserContext.Consumer>
            {({user}) =>
                this.getCovidCalendar(user)
            }
          </UserContext.Consumer>
        </div>
      </div>
    )
  }

  getCovidCalendar(user) {
    return (this.state.entries) ? (
      <div>
        { this.months.map(month => {
          const monthStart = moment.tz('2022-0' + month + '-01', "Europe/Brussels");

          return (
              <Card>
                <h2>
                  { monthStart.format('MMMM')}
                </h2>
                <br />
                <div className="covidMonth">{this.getMonth(month, monthStart, user)}</div>
              </Card>
          )
        })}
      </div>
    ) : <Loader />

  }

  getMonth(month, monthStart, user) {

    const days = monthStart.daysInMonth();
    const content = [];

    for (let i = 1; i <= days; i++) {
      let iString = i;

      if (i < 10) {
        iString = '0' + i;
      }

      const day = moment.tz('2022-0' + month + '-' + iString, "Europe/Brussels");
      const key = day.format("YYYY-MM-DD");
      const entries = this.state.entries.get(key);
      let claimed = false;
      let extraClass = "";

      let onClick = (() => {
        this.claimEntry(key, user.id, user.name);
      });

      if (entries && entries.length > 0) {
        entries.forEach(entry => {
          if (user.id === entry.user_id) {
            claimed = true;
          }
        })

        if (claimed) {
          extraClass = "claimedOwn";
          onClick = (() => {
            this.removeEntry(key, user.id);
          });

        } else {
          extraClass = "claimed";
        }
      }

      content.push(
          <div key={key} className="calendarDay">
            <div className="day">
              { day.format('dddd DD MMMM') }
            </div>
            <div className={"names " + extraClass} onClick={onClick}>
              { entries && entries.map(entry =>
                  <span key={key + user.user_id} className="name">
                    { entry.name }
                  </span>
              )}
            </div>
          </div>
      )
    }

    return content;
  }

  claimEntry(dayKey, userId, name) {
    const entries = this.state.entries;
    let day = entries.get(dayKey);

    if (!day) {
      day = [];
      entries.set(dayKey, day);
    }

    day.push({
      user_id: userId,
      name,
      day: moment.tz(dayKey, "Europe/Brussels").valueOf()
    })

    this.setState({
      entries
    });
  }

  removeEntry(dayKey, userId) {
    const entries = this.state.entries;
    let day = entries.get(dayKey);

    let entry = day.find(e => {
      return e.user_id === userId
    });

    console.log(entry);
    if (entry) {
      console.log(day.indexOf(entry));

      day.splice(day.indexOf(entry), 1);
    }

    console.log(day);

    this.setState({
      entries
    });
  }

  save(userId) {
    let entries = [];

    for (let day of this.state.entries.values()) {
      day.forEach(entry => {
        if (entry && entry.user_id === userId) {
          entries.push(Number(entry.day));
        }
      })
    }

    this.setState({
      pending_update: true
    });

    axios.post("/api/covidcalendar/" + this.props.match.params.group, {
      entries: entries
    }).then((response) => {
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success("Kalender opgeslagen!");
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Kon de Kalender niet opslaan!");
    }).finally(() => {
      this.setState({
        pending_update: false
      });
    })
  }
}

export default CovidCalendar;
