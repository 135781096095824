import axios from "axios";

class NotificationThread {
  constructor(fetchUser, updateNotifications) {
    this.fetchUser = fetchUser;
    this.updateNotifications = updateNotifications;

    this.interval = setInterval(this.fetch.bind(this), 60 * 1000);
    this.fetch();
  }

  destroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  async fetch() {
    const user = this.fetchUser();

    if (user) {
      axios.get("/api/notifications/unread")
        .then(result => {
          if (result.data.success) {
            this.updateNotifications(result.data.unread);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}

export default NotificationThread;