import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {isMobile} from 'react-device-detect';
import Moment from "react-moment";
import ReactHtmlParser from 'react-html-parser';

import 'moment-timezone';
import 'moment/locale/nl';

import Alert from "react-bootstrap/Alert";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

import "./SeatingPlan.css";

class SeatingPlan extends React.Component {

  constructor(props) {
    super(props);

    this.rowNames = ["A", "B", "C", "D", "E", "F", "G", "H"];

    this.state = {
      reservations: [],
      filtered_reservations: [],
      seats: null,
      activeReservation: null,
      pending_update: false
    };

    this.filterReservations = this.filterReservations.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.fetchSeatingPlan();
  }

  fetchSeatingPlan() {
    axios.get("/api/seating_plan/" + this.props.match.params.show_id)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          let reservations = response.data.reservations;

          reservations.forEach(reservation => {
            reservation.placed = 0;
            reservation.tickets = Number(reservation.tickets);
          });

          let seats = [];

          for (let i = 0; i < 7; i++) {
            let row = [];

            for (let j = 0; j < 14; j++) {
              row.push(0);
            }

            seats.push(row);
          }

          response.data.seats.forEach(seat => {
            seats[seat.row][seat.seat] = {
              reservation: seat.reservation,
              arrived: seat.entered
            };

            let reservation = reservations.find(reservation => {
              return reservation.id === seat.reservation
            });

            if (reservation) {
              reservation.placed += 1;
            }
          });

          this.setState({
            reservations: reservations,
            filtered_reservations: reservations,
            show: response.data.show,
            seats: seats
          });
        }
      }).catch((error) => {
      console.log(error);
      toast.error("Kon de reservaties niet ophalen.");
    });
  }

  render() {
    return (
      <div>
        {(isMobile) ? <Alert variant="danger">Je kan alleen op een computer stoelen verplaatsen! Je kan wel het plan bekijken om mobiel</Alert> : ''}
        {(this.state.show) ? (
          <Card className="d-flex flex-with-buttons justify-content-sm-between">
            <h1 className="text-center">
              {this.state.show.name}&nbsp;&nbsp;&nbsp;
              <Moment tz="Europe/Brussels" format="DD-MM-YYYY HH:mm">{Number(this.state.show.show_time)}</Moment>
            </h1>
            {this.state.pending_update ? <Loader /> : <Button variant="success" onClick={this.save}>Opslaan</Button>}
          </Card>
        ) : <span/>}
        <Row>
          <Col md={8}>
            <Row>
              <Card className="seatingPlan" md={12}>
                <h4>Podium</h4>
                {(this.state.seats) ? this.getSeatingArea() : <Loader />}
              </Card>
            </Row>
            <Row>
              <Card md={12}>
                {this.getReservation()}
              </Card>
            </Row>
          </Col>
          <Col md={4}>
            <h4>Reservaties</h4>
            <Card>
              <div className="form-group">
                <input type="text" placeholder="Zoek op naam" className="form-control" onChange={this.filterReservations}/>
              </div>
              <div className="reservationList">
                {this.getManualReservation()}
                {this.getReservationList()}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

  getSeatingArea() {
    let rows = [];

    for (let row = 0; row < 7; row ++) {
      rows.push(<div className="seatingRow">{this.getRow(row)}</div> );
    }

    return rows;
  }

  getRow(rowId) {
    let row = [];

    row.push(<div className="rowName">{this.rowNames[rowId]}</div>);

    for (let seatID = 0; seatID < 14; seatID++) {
      let seat = this.state.seats[rowId][seatID];
      let classes = "seat";

      let clickHandle;
      let doubleClickHandle;
      let rightClickHandle;

      if (seat.reservation && seat.reservation > 0) {
        if (this.state.activeReservation && this.state.activeReservation.id === seat.reservation) {
          classes += " selected-seat";

          if (!isMobile) doubleClickHandle = () => this.removeSeat(rowId, seatID);
        } else {
          classes += " taken-seat";
          clickHandle = () => this.setReservation(seat.reservation);
        }

        rightClickHandle = (event) => this.enteredSeat(event, rowId, seatID);
      } else if (seat.reservation === "-1") {
        if (!isMobile && this.state.activeReservation) doubleClickHandle = () => this.removeSeat(rowId, seatID);
        classes += " manual-seat";
        rightClickHandle = (event) => this.enteredSeat(event, rowId, seatID);
      } else if (this.state.activeReservation) {
        if (!isMobile) doubleClickHandle = () => this.addSeat(rowId, seatID);
      }

      if (seat.arrived) classes += " arrived";

      if (seatID > 0 && seatID < 13) {
        row.push(<div className={classes} onClick={clickHandle} onDoubleClick={doubleClickHandle} onContextMenu={rightClickHandle}>{seatID}</div>);
      } else {
        row.push(<div className={classes} onClick={clickHandle} onDoubleClick={doubleClickHandle} onContextMenu={rightClickHandle}>{(isMobile) ? 's' : 'stoel'}</div>);
      }
    }

    return row;
  }

  getReservation() {
    return ((this.state.activeReservation) ? (
      (this.state.activeReservation.id > 0) ? (
        <div>
          <h4>Reservatie #{this.state.activeReservation.id}</h4>
          <span><b>Naam</b>: {this.state.activeReservation.name} {this.state.activeReservation.firstName}</span>
          <br />
          {this.state.activeReservation.payed ? '' : [<b className="unpaid">ONBETAALD</b>, <br />]}
          <span><b>Tickets</b>: {this.state.activeReservation.tickets}</span>
          <br />
          <span><b>Rolstoel</b>: {(this.state.activeReservation.wheelchair) ? "Ja" : "Neen"}</span>
          <br />
          <span><b>Abonnementen</b>: {(this.state.activeReservation.has_subscription) ? "Ja" : "Neen"}</span>
          <br />
          <b>Opmerkingen</b>:
          <br />
          <span>{ ReactHtmlParser(this.state.activeReservation.remarks) }</span>
        </div>
      ) : (
        <div>
          <h4>Tickets aan de kassa</h4>
          <span>Klik op een plaats om deze als bezet te markeren voor een ticket gekocht aan de kassa</span>
        </div>
      )
    ) : (
      <h4>Selecteer een reservatie</h4>
    ))
  }

  getManualReservation() {
    let classes = "reservation";

    if (this.state.activeReservation && this.state.activeReservation.id === "-1") {
      classes += " selected";
    }

    return (
      <div className={classes} onClick={() => this.setReservation("-1")}>
        <b>Reservatie aan kassa</b>
        <br />
        <span>Klik hier om plaatsen toe te wijzen bij betaling aan kassa</span>
      </div>
    )
  }

  getReservationList() {
    return this.state.filtered_reservations.map((reservation) => {
      let classes = "reservation";

      if (this.state.activeReservation && reservation.id === this.state.activeReservation.id) {
        classes += " selected";
      }

      return (
        <div className={classes} onClick={() => this.setReservation(reservation.id)}>
          <b>Reservatie #{reservation.id}</b>
          <br />
          <span>{reservation.name} {reservation.firstName}</span>
          <br />
          <span><b>Tickets</b>: {reservation.tickets}</span>
          <br />
          <span className={(reservation.tickets === reservation.placed) ? '' : 'missingPlaced'}><b>Geplaatst</b>: {reservation.placed}</span>
          <br />
          {reservation.wheelchair ? [<b className="wheelchair">ROLSTOEL</b>, <br/>] : ''}
          {reservation.has_subscription ? <b className="subscription">Abonnement</b> : ''}
          {reservation.payed ? '' : <b className="unpaid">ONBETAALD</b>}
        </div>
      )
    });
  }

  filterReservations(event) {
    let name = event.target.value;

    let reservations = this.state.reservations;
    let filtered = reservations.filter(reservation => {
      return (reservation.name + " " + reservation.firstName).toLocaleLowerCase().indexOf(name.toLocaleLowerCase()) >= 0;
    });

    this.setState({
      filtered_reservations: filtered
    });
  }

  setReservation(id) {
    if (id > 0) {
      let reservation = this.state.reservations.find((reservation) => {
        return reservation.id === id
      });

      if (reservation) {
        this.setState({
          activeReservation: reservation
        });
      }
    } else if (id === "-1") {
      this.setState({
        activeReservation: {
          id: "-1"
        }
      });
    }
  }

  addSeat(row, seat) {
    let reservation;

    if (this.state.activeReservation.id > 0) {
      reservation = this.state.reservations.find(reservation => {
        return reservation.id === this.state.activeReservation.id
      });

      if (!reservation) {
        toast.error("Er is iets mis met de actieve reservatie. Gelieve opnieuw een reservatie te kiezen.");
        return;
      }

      if (reservation.placed === reservation.tickets) {
        toast.error("Reeds max aantal plaatsen gekozen. Verwijder eerst plaatsen om ze te verplaatsen!");
        return;
      }
    }

    let seats = this.state.seats;
    seats[row][seat] = {
      reservation: this.state.activeReservation.id,
      arrived: false
    };

    if (reservation) reservation.placed = reservation.placed + 1;

    this.setState({
      seats: seats
    });
  }

  removeSeat(row, seat) {
    if (this.state.activeReservation.id > 0) {
      let reservation = this.state.reservations.find(reservation => {
        return reservation.id === this.state.activeReservation.id
      });

      if (!reservation) {
        toast.error("Er is iets mis met de actieve reservatie. Gelieve opnieuw een reservatie te kiezen.");
        return;
      }

      reservation.placed = reservation.placed - 1;
    }

    let seats = this.state.seats;
    seats[row][seat] = {
      reservation: 0,
      arrived: false
    };

    this.setState({
      seats: seats
    });
  }

  enteredSeat(event, row, seat) {
    event.preventDefault();

    let seats = this.state.seats;
    seats[row][seat].arrived = !seats[row][seat].arrived;

    this.setState({
      seats: seats
    });
  }

  save() {
    let seatings = this.state.seats;
    let seats = [];

    for (let row = 0; row < seatings.length; row++) {
      for (let seatID = 0; seatID < seatings[row].length; seatID++) {
        if (seatings[row][seatID].reservation > 0 || seatings[row][seatID].reservation === "-1") {
          let seat = seatings[row][seatID];

          seats.push({
            row: row,
            seat: seatID,
            reservation: seat.reservation,
            entered: seat.arrived
          });
        }
      }
    }

    this.setState({
      pending_update: true
    });

    axios.post("/api/seating_plan/" + this.props.match.params.show_id, {
      seats: seats
    }).then((response) => {
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success("Zaalplan opgeslagen!");
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Kon het zaalplan niet opslaan!");
    }).finally(() => {
      this.setState({
        pending_update: false
      });
    })
  }
}

export default SeatingPlan;