import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import Row from "react-bootstrap/Row";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class PassReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: false,
      valid: false,
      pass: "",
      pass1: "",
      pending: false,
      updated: false
    };

    this.changePass = this.changePass.bind(this);
    this.changePass1 = this.changePass1.bind(this);
    this.request = this.request.bind(this);
  }

  componentDidMount() {
    axios.get("/api/resetpass/" + this.props.match.params.reset_id)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            valid: true
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon link niet valideren, probeer pagina opnieuw te laden");
      }).finally(() => {
        this.setState({
          verified: true
        });
      });
  }

  render() {
    return (
      <Row>
        <Card className="login-box" md={4}>
          <h1>TDM Ledenzone</h1>

          {(this.state.verified) ? (
            (this.state.valid) ? (
              <div>
                <p>Geef je nieuwe wachtwoord in om je wachtwoord opnieuw in te stellen.</p>

                <form onSubmit={this.request}>
                  <div className="form-group">
                    <input id="pass" name="pass" type="password" value={this.state.pass} onChange={this.changePass} className="form-control" placeholder="Wachtwoord" autoComplete="current-password" required/>
                  </div>

                  <div className="form-group">
                    <input id="pass1" name="pass1" type="password" value={this.state.pass1} onChange={this.changePass1} className="form-control" placeholder="Herhaal Wachtwoord" autoComplete="password-repeat" required/>
                  </div>

                  <div className="form-group">
                    {(this.state.pending) ? (
                      <Loader />
                    ) : (
                      <button className="btn btn-primary btn-block btn-flat">
                        Wachtwoord aanpassen
                      </button>
                    )}
                  </div>
                </form>

                <div className="d-flex justify-content-between">
                  <Link to="/">Aanmelden</Link>
                  <Link to="/register">Registreren</Link>
                </div>
              </div>
            ) : (
              <p>Link is niet langer geldig.</p>
            )
          ) : (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
        </Card>
      </Row>
    );
  }

  changePass(event) {
    this.setState({pass: event.target.value});
  }

  changePass1(event) {
    this.setState({pass1: event.target.value});
  }

  async request(e) {
    e.preventDefault();

    if (!this.state.pass || this.state.pass === "") {
      toast.error("Gelieve een wachtwoord in te vullen!");
      return;
    }

    if (!this.state.pass1 || this.state.pass1 === "") {
      toast.error("Gelieve je wachtwoord te herhalen!");
      return;
    }

    if (this.state.pass !== this.state.pass1) {
      toast.error("U wachtwoorden komen niet over elkaar");
      return;
    }

    this.setState({
      pending: true
    });

    axios.post("/api/resetpass/" + this.props.match.params.reset_id, {
      pass: this.state.pass
    }).then((response) => {
      if (response.data.success) {
        toast.success("Wachtwoord opnieuw ingesteld. U wordt doorgestuurd naar de login pagina...");
        this.setState({
          updated: true
        });
        this.props.history.push("/");
      } else {
        toast.error(response.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Er is iets fout gegaan bij het aanpassen van het wachtwoord. Indien dit blijft gebeuren gelieve dit te melden!");
    }).finally(() => {
      if (!this.state.updated) {
        this.setState({
          pending: false
        });
      }
    });
  }
}

export default PassReset;