import axios from 'axios';

async function fetchPermissions(userID) {
  try {
    const response = await axios.get("/api/permissions/" + userID);
    if (!response.data.success) {
      return null;
    } else {
      return response.data.permissions;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export default fetchPermissions;