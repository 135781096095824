import React from 'react';
import {Link} from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import Card from "../components/Card";

class Home extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1><strong>De Moedertaal - LedenZone</strong></h1>
            <h4>Welkom op de nieuwe Ledenzone van De Moedertaal.</h4>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={5}>
            <h2>Wat is de bedoeling van de Ledenzone?</h2>
            <p>
              We willen graag alle TDM Theater De Moedertaal activiteiten centraliseren.
            </p>
            <p>
              Zo zijn meteen alle leden op de hoogte van repetitiedata, scripts, belangrijke afspraken,…
            </p>
          </Card>
          <Card md={5} >
            <h2>Welke stappen hebben we ondertussen gezet?</h2>
            <p>
              <strong>18-06-2019</strong>
            </p>
            <p>
              In fase 2 krijgen alle leden van het theater toegang tot de ledenzone.
              Dat wil zeggen dat iedereen via een <Link to="/profile">eigen profiel</Link> de persoonlijke gegevens ingeeft en het lidgeld betaalt.
            </p>
            <p>
              <strong>01-08-2018</strong>
            </p>
            <p>
              De verbeterde versie van reservatiesysteem wordt gekoppeld aan een gebruiksvriendelijke betalingswijze.
            </p>
            <p>
              <strong>29-01-2018</strong>
            </p>
            <p>
              De eerste fase van de ledenzone gaat online voor het bestuur.
              De klemtoon ligt op het operationeel maken van een nieuw reserveringssysteem en op het beheer daarvan door het bestuur.
            </p>
          </Card>
        </Row>
      </div>
    )
  }
}

export default Home;