import React from 'react';

import {UserContext} from "../context/UserContext";

import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell} from '@fortawesome/free-solid-svg-icons';

import "./NotificationButton.css";

class NotificationButton extends React.Component {
  render() {
    return (
      <UserContext.Consumer>
        {({user}) =>
          (user && user.notifications && user.notifications.length > 0) ?
            <Link className="notification_btn" to="/notifications">
              <FontAwesomeIcon icon={faBell}/>
              <span className="amount">{user.notifications.length}</span>
            </Link>
            : ''
        }
      </UserContext.Consumer>
    );
  }
}

export default NotificationButton;