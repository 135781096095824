import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/nl';

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Card from "../components/Card";
import Loader from "../components/Loader";

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unread: null,
      read: null,
      pending: false
    };

    this.markRead = this.markRead.bind(this);
  }

  componentDidMount() {
    this.fetchNotifications();
  }

  fetchNotifications() {
    axios.get("/api/notifications")
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            unread: response.data.unread,
            read: response.data.read
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de notificaties niet ophalen.");
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <div className="d-flex justify-content-between flex-with-buttons">
              <h4>Ongelezen notificaties</h4>
              {(this.state.pending) ? <Loader /> : <Button variant="success" onClick={this.markRead}>Markeer alles gelezen</Button>}
            </div>

            {(this.state.unread) ? (
              <div className="table-list">
                {this.getNotifications(this.state.unread)}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>

        <Row>
          <Col md={1} />
          <Card md={10}>
            <h4>Gelezen notificaties</h4>

            {(this.state.read) ? (
              <div className="table-list">
                {this.getNotifications(this.state.read)}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
      </div>
    )
  }

  getNotifications(notifications) {
    if (notifications.length > 0) {
      return notifications.map((notification) => (
        <div>
          <Link to={notification.url}>{notification.text}</Link> <Moment tz="Europe/Brussels" locale="nl" fromNow>{Number(notification.time)}</Moment>
        </div>
      ));
    } else {
      return <p>Geen notificaties</p>
    }
  }

  markRead() {
    this.setState({
      pending: true
    });

    axios.post("/api/notifications/all")
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          toast.success("Notificaties gemarkeerd als gelezen");
          this.fetchNotifications();
          this.props.updateNotifications([]);
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de notificaties niet markeren als gelezen.");
      }).finally(() => {
        this.setState({
          pending: false
        });
      });
  }
}

export default Notifications;