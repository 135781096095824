import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

import Moment from 'react-moment';
import 'moment-timezone';

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Card from "../../components/Card";
import Loader from "../../components/Loader";

class Shows extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shows: null,
      info: null
    };
  }

  componentDidMount() {
    this.fetchShows();
  }

  fetchShows() {
    axios.get("/api/shows/" + this.props.match.params.show_code)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            shows: response.data.shows,
            info: response.data.info
          });
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de voorstellingen niet ophalen.");
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Overzicht voorstellingen</h1>
            <p>Selecteer hier een voorstelling voor welke je alle reservaties wilt bekijken.</p>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>{(this.state.shows) ? this.state.shows[0].name : ''}</h1>
            {(this.state.shows) ? (
              <div>
                <p>
                  <b>Aantal reservaties:</b> {this.state.info.number_reservations}
                </p>
                <p>
                  <b>Totaal tickets</b>: {this.state.info.total_tickets}
                </p>
                <p>
                  <b>Aantal abonnementen</b>: {this.state.info.subscriptions}
                </p>
                <p>
                  <b>Aantal vrijkaarten</b>: {this.state.info.total_free}
                </p>
                <p>
                  <b>Aantal uitpas</b>: {this.state.info.total_uitpas}
                </p>
                <p>
                  <b>Aantal vroegboekkorting</b>: {this.state.info.total_early_bird}
                </p>
                <p>
                  <b>Aantal manuele tickets</b>: {this.state.info.total_manual}
                </p>
                <p>
                  <b>Totale omzet</b>: €{this.state.info.total_income.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                </p>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            {(this.state.shows) ? (
              <div className="table-list">
                {this.getShows(this.state.shows)}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
      </div>
    )
  }

  getShows(shows) {
    return shows.map((show) => {
      const url = "/show/" + show.id;

      return (
        <div key={show.id} className="d-flex justify-content-between flex-with-buttons">
          <p>
            <Moment tz="Europe/Brussels" format="DD-MM-YYYY HH:mm">
              {Number(show.show_time)}
            </Moment>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;{show.reserved}/{show.places}
          </p>
          <div>
            <Button as={Link} to={url} className="btn btn-info mr-3">Bekijk Reservaties</Button>
          </div>
        </div>
      )
    })
  }
}

export default Shows;